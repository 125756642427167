export const NAVBAR_MENU = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about-us" },
  { name: "ShopeePay", path: "/shopeepay" },
  { name: "SPayLater", path: "/spaylater" },
  { name: "SLoan", path: "/sloan" },
  {
    name: "Insurance",
    path: "/insurance",
    children: [
      { name: "Insurance", path: "/insurance" },
      { name: "Car Insurance", path: "/insurance/car" },
      { name: "Motorcycle Insurance", path: "/insurance/motorcycle" },
      { name: "Travel Insurance", path: "/insurance/travel" },
      {
        name: "Personal Accident (PA) Insurance",
        path: "/insurance/personal-accident",
      },
    ],
  },
  {
    name: "Merchant",
    path: "/merchant",
    children: [
      { name: "Be a Partner", path: "/merchant/be-a-partner" },
      { name: "SLoan for Sellers", path: "/merchant/sloan-for-sellers" },
      { name: "Quick Funds", path: "/merchant/quick-funds" },
    ],
  },
  // { name: "Product", path: "https://product.shopeepay.com", isExternal: true },
];
